<template>
    <!-- 修改固定数据页 -->
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>服务器</el-breadcrumb-item>
                <el-breadcrumb-item>修改固定数据</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="form">
            <div class="item">
                <div class="lable">vCPU</div>
                <div class="content">
                    <el-tag
                        type="info"
                        effect="plain"
                        v-for="el in cpuList"
                        :key="el"
                        closable
                        @close="cpuList = cpuList.filter(e => e !== el)"
                        >{{ el }}</el-tag
                    >
                    <el-input
                        placeholder="+ 添加"
                        class="tagIpt"
                        v-model.trim="iptVal1"
                        size="small"
                        @keyup.enter.native="add('cpuList', iptVal1, $event)"
                        @blur="add('cpuList', iptVal1, $event)"
                    >
                    </el-input>
                </div>
            </div>
            <div class="item">
                <div class="lable">内存</div>
                <div class="content">
                    <el-tag
                        type="info"
                        effect="plain"
                        v-for="el in memoryList"
                        :key="el"
                        closable
                        @close="memoryList = memoryList.filter(e => e !== el)"
                        >{{ el }}</el-tag
                    >
                    <el-input
                        placeholder="+ 添加"
                        class="tagIpt"
                        v-model="iptVal2"
                        size="small"
                        @keyup.enter.native="add('memoryList', iptVal2, $event)"
                        @blur="add('memoryList', iptVal2, $event)"
                    >
                    </el-input>
                </div>
            </div>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <!-- <VyIcon name="取消" scale="4" @click.native="$emit('stop')"></VyIcon> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { VyIcon },
    data() {
        return {
            cpuList: [],
            memoryList: [],
            iptVal1: "",
            iptVal2: "",
        };
    },
    async created() {
        let { cpu, memory } = await this.getData();
        this.cpuList = cpu;
        this.memoryList = memory;
    },
    methods: {
        async getData() {
            let {
                data: { cpu, memory },
            } = await axios.get("/api/server/cpushow");
            cpu = cpu.map(e => e.cpu);
            memory = memory.map(e => e.memory);
            return { cpu, memory };
        },
        add(key, val, e) {
            if (!val) return;
            if (this[key].findIndex(e => e === val) !== -1) return this.$message.error("请勿添加重复数据");
            this[key].push(val);
            this.iptVal1 = "";
            this.iptVal2 = "";
            e.target.blur();
        },
        // 提交
        async yes() {
            let { cpuList: cpu, memoryList: memory } = this;
            let { data } = await axios.post("/api/server/cpuupdate", { cpu, memory });
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .form {
        background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
        .item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            .lable {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                border: 0;
                border-radius: 20px 0 0 20px;
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }
            .content {
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap;
                flex-wrap: wrap;
                /deep/.el-input__inner {
                    flex: 1;
                }
                /deep/.el-switch {
                    // margin-left: 20px;
                    margin: 0 20px;
                }
                /deep/.el-switch__label.is-active {
                    color: #7781f1;
                }
                /deep/.el-tag {
                    margin-left: 10px;
                    // color: #7781f1;
                    // background-color: transparent;
                    // border-color: #7781f1;
                }
            }
        }
    }
    .tagIpt {
        width: 72px;
        margin-left: 10px;
        vertical-align: bottom;
    }
}
</style>
